@import '../../styles/vars.less';

.login-layout {
  display: flex;
  height: 100vh;
  position: relative;
}

.logo-area {
  flex: 1;
  background-color: #2b7bf4;
  background-image: url('../../assets/images/login-background.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-form-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-area {
  width: 80%;
  max-width: 400px;
}

.form-content {
  width: 70%;
  margin: auto;
}

.login-title {
  font-weight: 600;
  font-size: 54px;
  line-height: 64px;
  color: @bodyFontColor;
  margin-bottom: 46px;
}

.studios-area-login-title {
  font-size: 20px;
  font-weight: 600;
  color: @bodyFontColor;
  margin-bottom: 20px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  box-shadow: 0 4px 10px rgb(16 156 241 / 24%);
  border-radius: 6px;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
}

.logo-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .logo-form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 32px;
  }

  .form-area {
    width: 100%;
    background: #fff;
    box-shadow: 0 10px 26px rgb(8 80 189 / 42%);
    border-radius: 16px;
    padding: 10px 0;
  }

  .login-title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .form-content {
    width: 100%;
    padding: 32px;
  }

  .logo-mobile {
    display: block;
    margin-bottom: 32px;
  }
}
