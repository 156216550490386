@import '../../../styles/vars.less';

.layout {
  min-height: 100vh;
}

.header {
  position: fixed;
  z-index: 99;
  width: 100%;
  display: flex;
}

.content {
  padding: 12px 16px;
  margin-top: 64px;
  min-width: 1200px;
}

.silder {
  flex: 0 0 278px;
  max-width: 278px;
  min-width: 278px;
  width: 278px;
}

.silder-content {
  color: @white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.silder-menu {
  flex: 1;
}

.silder-footer {
  padding: 24px;
}

.site-layout {
  background-color: @backgroundColor;
  padding: 24px 8px;
  overflow-x: hidden;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

.user-icon {
  font-size: 20px;
}

.user-email {
  font-size: 14px;
  font-weight: 500;
  color: @white;
}

.user-role {
  font-size: 12px;
  color: #fff9;
}

.silder-footer-operation {
  width: 100%;
  padding: 15px 0;
  justify-content: space-between;

  .logout-icon,
  .right-icon,
  .left-icon {
    font-size: 18px;
    color: #a5a3a4;
  }
}

.silder-logo {
  margin-left: 24px;
  height: 56px;
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 10px;
}

.silder-logo-collapsed {
  margin-left: 42px;
}
