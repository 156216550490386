@import '../../../styles/vars.less';

.card-stack-desc-wrapper {
  max-width: @maxScreenWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.button-group {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  padding: 16px 0;
}
