.coupon-form {
  margin: 0 auto;
}

.save-button {
  margin-left: 8px;
}

.buy-one-get-more-wrapper {
  display: flex;
  align-items: center;
}
