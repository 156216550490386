.lesson-credit-pricing-space {
  width: 100%;
}

.left-col {
  text-align: left;
  font-size: 16px;
}

.right-col {
  text-align: right;
}

.filter-bar {
  background-color: #f4f6f9;
  padding: 16px;
}

.edit-button {
  border-color: #40a9ff;
  color: #40a9ff;
}
