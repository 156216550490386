@import '../../../../styles/vars.less';

.billing-summary-header {
  margin-top: 16px;
  margin-bottom: 17px;
}

.billing-summary-tips {
  color: @secondaryColor;
  font-size: 12px;
  line-height: 24px;
}
