.action-item {
  display: flex;
  align-items: center;
  width: 50px;
  gap: 5px;
}

.activate-button {
  color: #389e0d;

  &:hover {
    color: #389e0d;
  }
}
