.loading-wrap {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: #fff;
  transition: all 2s;
}
