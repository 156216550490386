.coupon-template-desc-wrapper {
  max-width: 855px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group {
  margin-top: 200px;
}

.user-ids-box {
  max-height: 200px;
  overflow: auto;
}
