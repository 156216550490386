@import '../../../styles/vars.less';

.left-col {
  text-align: left;
  font-size: 16px;
}

.right-col {
  text-align: right;
}

.filter-bar {
  background-color: #f4f6f9;
  padding: 16px;
}

.coupon-list-space {
  width: 100%;
}
