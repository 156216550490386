.studio-leaderboard-category-form {
  max-width: 1000px;
  margin: 0 auto;
}

.tooltop-title {
  margin-bottom: 10px;
}

.tooltip-inner,
.tooltip-inner-image {
  position: relative;
  width: 100%;
}
