@import '../../../styles/vars.less';

.button-group {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button-wrapper {
  display: flex;
  gap: 8px;
  flex-flow: row wrap;
}

.corporate-member-link-button {
  width: fit-content;
}
