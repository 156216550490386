.menu-col {
  cursor: pointer;
}

.menu-card {
  text-align: center;
}

.menu-icon {
  font-size: 30px;
}
