@import '../../../styles/vars.less';

.button-group {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wechat-url-button,
.web-url-button {
  width: 200px;
  height: 100px;
}
