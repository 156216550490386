@import '../../../styles/vars.less';

.billing-summaries-studios-select {
  width: 350px;

  :global {
    .ant-select-selector {
      border-radius: 20px !important;
    }
  }
}
