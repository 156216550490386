@font-face {
  font-family: category-iconfont; /* Project id 4684227 */
  src:
    url('iconfont.woff2?t=1726296993309') format('woff2'),
    url('iconfont.woff?t=1726296993309') format('woff'),
    url('iconfont.ttf?t=1726296993309') format('truetype');
}

.category-iconfont {
  font-family: category-iconfont !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-water-activities::before {
  content: '\e66c';
}

.icon-bicycle::before {
  content: '\e66d';
}

.icon-leisure::before {
  content: '\e660';
}

.icon-opengym::before {
  content: '\e661';
}

.icon-outdoor::before {
  content: '\e662';
}

.icon-personal-training::before {
  content: '\e663';
}

.icon-women-only::before {
  content: '\e664';
}

.icon-yoga::before {
  content: '\e665';
}

.icon-ball-game::before {
  content: '\e666';
}

.icon-pilates::before {
  content: '\e667';
}

.icon-weight-training::before {
  content: '\e668';
}

.icon-hotel-gym-and-pool::before {
  content: '\e669';
}

.icon-cardio::before {
  content: '\e66a';
}

.icon-aerial::before {
  content: '\e66b';
}

.icon-rehabilitation::before {
  content: '\e65f';
}

.icon-niche-sport::before {
  content: '\e659';
}

.icon-dance::before {
  content: '\e65a';
}

.icon-barre::before {
  content: '\e657';
}

.icon-beauty-spa::before {
  content: '\e658';
}

.icon-meditation::before {
  content: '\e65b';
}

.icon-martial-arts::before {
  content: '\e65c';
}

.icon-functional-training::before {
  content: '\e65d';
}

.icon-healthy-eating::before {
  content: '\e65e';
}
