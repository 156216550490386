@import '../../../styles/vars.less';

.booking-review-desc-wrapper {
  max-width: @maxScreenWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group {
  margin-top: 200px;
}

.timeline {
  padding: 16px 0;
}
