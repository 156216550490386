@import '../../styles/vars.less';

.change-language-wrapper {
  background-color: @white;
  padding: 100px;
  text-align: center;
}

.radio-group {
  text-align: left;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
