.credit-exchange-rate-space {
  width: 100%;
}

.left-col {
  text-align: left;
  font-size: 16px;
}

.right-col {
  text-align: right;
}

.edit-button {
  border-color: #40a9ff;
  color: #40a9ff;
}
