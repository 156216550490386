@import '../../../../styles/vars.less';

.billing-summary-tips {
  width: 100%;
  padding: 3px 12px;
  background-color: @lightOrange;
  color: @bodyFontColor;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  line-height: 24px;
  flex: 1;

  & .show-billing-invoice-process-modal-button {
    padding: 0;
    line-height: 24px;
    font-size: 12px;
    height: auto;
  }
}

.billing-summary-header {
  margin-top: 16px;
  margin-bottom: 17px;
}

.billing-summary-card {
  border-radius: 24px;
  height: 170px;
  color: @bodyFontColor;
  text-align: center;
  font-size: 12px;

  & .symbol {
    font-size: 20px;
    font-weight: 700;
  }

  & .number {
    font-size: 40px;
    font-weight: 700;
    white-space: nowrap;
  }

  & .description {
    font-size: 18px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  & .compare-number {
    color: @secondaryColor;
    white-space: nowrap;
  }

  & .compare-number-black-word {
    white-space: nowrap;
  }

  & .sub-number {
    font-size: 24px;
    font-weight: 700;
    margin-right: 8px;
  }
}

.red-word {
  color: @red;
}

.green-word {
  color: @green;
}

.progress-bar {
  width: 85px;
}

.divider {
  height: 109px;
}

.billing-summary-card-container {
  overflow-y: hidden;
}
