.lesson-credit-adjustments-form {
  max-width: 1000px;
}

.lesson-credit-adjustments-steps {
  width: 1000px;
  margin: auto;
  margin-bottom: 20px;
}

.check-box {
  display: flex;
}

.lesson-credit-adjustments-form-container {
  width: 100%;
}
