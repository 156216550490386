@import 'vars';

html {
  background: #f3f5f6;
}

html,
body {
  font-size: @bodyFontSize !important;
  line-height: 1.75;
  color: @bodyFontColor;
}

pre {
  overflow: auto;
  white-space: pre-wrap;
}

.link-span {
  cursor: pointer;
  color: @primaryColor;
}

.link-icon {
  cursor: pointer;
}

// ant didn't support custom selected item display, hack using css
.ant-select-selection-selected-value {
  .hide-in-selection-selected {
    display: none;
  }
}

.ant-table {
  overflow: auto;
  width: 100%;
}

.dashboard-wrapper {
  width: 100%;
}

.table-space {
  width: 100%;
}

.public-dashboard-wrapper {
  width: 100%;
  padding: 16px;
  background-color: @white;
}

.left-col {
  text-align: left;
  font-size: 16px;
}

.right-col {
  text-align: right;
}

.filter-bar {
  background-color: #f4f6f9;
  padding: 16px;
}

.color-preview {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.desc-wrapper {
  max-width: @maxScreenWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.resource-form {
  max-width: 1000px;
  margin: 0 auto;
}

.sketch-picker {
  box-shadow: none !important;
}

// overwriting the upload image preview
.ant-upload-list-item-thumbnail,
.ant-upload-list-item-thumbnail img {
  width: auto !important;
}

.filter-inline-form {
  gap: 10px 0;
}

.status-button-blue {
  color: #096dd9;

  &:hover {
    color: #096dd9;
  }
}

.status-button-green {
  color: #389e0d;

  &:hover {
    color: #389e0d;
  }
}

.status-button-red {
  color: #cf1322;

  &:hover {
    color: #cf1322;
  }
}

.site-page-header {
  padding: 16px 0;
}

.clickable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.display-flex {
  display: flex;
  align-items: center;

  & .flex-1 {
    flex: 1;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.gap-12 {
    gap: 12px;
  }
}

.studios-area-page-title {
  color: @bodyFontColor;
  font-size: 32px;
  font-weight: 500;
}

.studios-area-card-title {
  color: @bodyFontColor;
  font-size: 22px;
  font-weight: 500;
}

.studios-area-card {
  border-radius: 24px;
  background-color: @white;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  width: 100%;
}
