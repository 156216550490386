@import '../../../styles/vars.less';

.reclaim-button {
  color: #389e0d;

  &:hover {
    color: #389e0d;
  }
}

.action-item {
  display: flex;
  align-items: center;
  width: 50px;
  gap: 5px;
}
