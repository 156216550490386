.card-config-form {
  max-width: 1000px;
  margin: 0 auto;
}

.tooltip-title {
  font-size: 30px;
  margin-bottom: 10px;
}

.tooltip-inner,
.tooltip-inner-image {
  position: relative;
  width: 100%;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.icon-radio {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .icon {
    font-size: 40px;
  }
}
