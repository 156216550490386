@import '../../styles/vars.less';

.layout {
  min-height: 100vh;
}

.header {
  position: fixed;
  z-index: 99;
  width: 100%;
  display: flex;
}

.content {
  padding: 12px 16px;
  margin-top: 64px;
  min-width: 1200px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 32px;
  line-height: 32px;
  margin: 16px 24px 16px 0;
  font-size: 18px;
  color: #fff;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.left-menu-container {
  flex: 1;
}

.right-menu {
  color: #fff;
}
