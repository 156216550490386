@import '../../../../styles/vars.less';

.invoice-card-content {
  padding: 0 8px;
}

.invoice-card-title {
  margin-bottom: 23px;
}

.special-explanation-description {
  :global {
    .ant-descriptions-view {
      background-color: @lightOrange;
      border-radius: 10px;
      padding: 16px;
      margin-top: 16px;
    }
  }
}

.show-modal-description-item {
  margin-top: 23px;

  & .show-modal-button {
    padding: 0;
  }
}

.red-word {
  color: @red;
}
