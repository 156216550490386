@import '../../../../styles/vars.less';

.modal-container {
  color: @bodyFontColor;
  font-weight: 500;
  padding-right: 70px;
}

.modal-content {
  color: @bodyFontColor;
  font-size: 12px;
  margin-bottom: 16px;
  margin-left: 12px;
}

.modal-sub-title {
  color: @bodyFontColor;
  font-size: 14px;
}

.modal-highlight {
  background-color: @lightOrange;
  border-radius: 8px;
  color: @red;
  margin: 12px 0;
  padding: 3px 12px;
}
